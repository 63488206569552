import React from 'react'
import axios from 'axios'

import './ContactForm.scss'
import { ABOUT_US_OPTIONS, FORM_SPREE_ENDPOINT } from '../../constants/constants.js'

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.formSubmit = this.formSubmit.bind(this)
  }

  formRefSubmit() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-contact-submit-form-contact-us'
    })
    setTimeout(() => {
      // give gtm time to update before redirecting page
      this.formRef.submit()
    }, 1000)
  }

  formSubmit(e) {
    e.preventDefault()
    this.emailInput.value = this.emailInput.value.trim()
    this.firstNameInput.value = this.firstNameInput.value.trim()
    this.lastNameInput.value = this.lastNameInput.value.trim()

    // const urlMailChimp = 'https://portal.ovationvr.com/api/v1/mailchimp/member/create'
    const urlMailChimp = 'https://app.prod.ovationvr.com/api/v1/mailchimp/member/create'
    const urlBrevo = 'https://app.prod.ovationvr.com/api/v1/brevo/member/create'
    if (this.mailingListCheckbox.checked) {
      axios
        .all([
          axios.post(urlMailChimp, {
            email: this.emailInput.value,
            firstName: this.firstNameInput.value,
            lastName: this.lastNameInput.value
          }),
          axios.post(urlBrevo, {
            email: this.emailInput.value,
            firstName: this.firstNameInput.value,
            lastName: this.lastNameInput.value
          })
        ])
        .then(() => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'gtm-newsletter-add-contact-contact-us-form'
          })
          // Finish submitting form - Wait for promise to resolve before redirecting to captcha page
          this.formRefSubmit()
        })
        .catch((err) => {
          console.log('err', err)
          // Finish submitting form - Wait for promise to reject before redirecting to captcha page
          this.formRefSubmit()
        })
    } else {
      // Finish submitting form
      this.formRefSubmit()
    }
  }

  render() {
    return (
      <div className="contact-form">
        <h2>Get in touch - we'll respond within 2 business days</h2>
        <div className="contact-form-inner">
          <form
            method="POST"
            className="ov-contact-form"
            action={FORM_SPREE_ENDPOINT}
            ref={(input) => {
              this.formRef = input
            }}
            onSubmit={(e) => this.formSubmit(e)}
          >
            <div className="form-group">
              <input className="form-control" style={{ display: 'none' }} name="_subject" value="Contact Us" readOnly />
            </div>
            <div className="form-group">
              <input
                required
                className="ov-contact-input form-control"
                name="firstName"
                placeholder="First Name"
                ref={(input) => {
                  this.firstNameInput = input
                }}
              />
            </div>
            <div className="form-group">
              <input
                className="ov-contact-input form-control"
                name="lastName"
                placeholder="Last Name"
                ref={(input) => {
                  this.lastNameInput = input
                }}
              />
            </div>
            <div className="form-group">
              <input
                required
                className="ov-contact-input form-control"
                name="email"
                placeholder="Email"
                type="email"
                ref={(input) => {
                  this.emailInput = input
                }}
              />
            </div>
            <div className="form-group">
              <textarea required className="ov-contact-textarea form-control" name="message" placeholder="Message" />
            </div>
            <div className="form-group">
              <label className="form-label-ovation">How did you hear about us?</label>
              <div style={{ position: 'relative' }}>
                <select name="aboutUsOption" className="form-select-ovation">
                  {ABOUT_US_OPTIONS.map(({ value, name }, index) => (
                    <option value={value} key={index}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ textAlign: 'left' }}>
              <div className="form-group">
                <div className="form-check">
                  <input
                    name="signUpForEmailList"
                    value="Yes"
                    className="form-check-input"
                    type="checkbox"
                    id="signUpForEmailList"
                    ref={(input) => {
                      this.mailingListCheckbox = input
                    }}
                    style={{ width: '18px' }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="signUpForEmailList"
                    style={{ marginTop: '15px', marginLeft: '5px' }}
                  >
                    Sign Up for Ovation email updates
                  </label>
                </div>
              </div>
            </div>
            <button className="ov-contact-button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  }
}
