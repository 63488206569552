import React from 'react'
import Helmet from 'react-helmet'

import ContactForm from '../components/ContactForm/ContactForm'

const ContactIndex = () => (
  <>
    <Helmet title={'Ovation | Contact Us'}>
      <meta
        name="description"
        content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
      />
      <meta name="keywords" content="VR, Public, Speaking, Training" />
    </Helmet>
    <ContactForm />
  </>
)

export default ContactIndex
